@import "bootstrap/dist/css/bootstrap.min.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "cytoscape-context-menus/cytoscape-context-menus.css";


.label-collapsed {
  color: #494949;
  font-size: 8px;
}
.ng-invalid .app-text-input.ng-dirty, .ng-invalid .value-input.ng-dirty {
  border: 2px solid red !important;
  background-color: #fff2f2 !important;
}

.edge-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 11111111;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.no-gutter > [class^="col-"], .no-gutter > [class*=" col-"] {
  padding-left: 0;
  padding-right: 0;
}

.mat-icon.material-symbols-outlined {
  font-family: "Material Symbols Rounded", sans-serif;
}


.unreachable-image {
  background-color: #ffdddd !important;

  input {
    background-color: #ffdddd !important;
  }
}

.no-border-input {
  border: none !important;
  outline: none !important;
  background: none !important;
  color: black;
}

.key-input {
  text-align: right;
}

.value-input {
  text-align: left;
}

.mat-mdc-slider {
  --mdc-slider-handle-color: #7f7f7f !important;
  --mdc-slider-focus-handle-color: #7f7f7f !important;
  --mdc-slider-hover-handle-color: #7f7f7f !important;
  --mdc-slider-active-track-color: #7f7f7f !important;
  --mdc-slider-inactive-track-color: #7f7f7f !important;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #7f7f7f !important;
  --mat-mdc-slider-ripple-color: #7f7f7f !important;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #7f7f7f !important;
  --mdc-switch-selected-handle-color: #555555 !important;
  --mdc-switch-selected-hover-state-layer-color: #999999 !important;
  --mdc-switch-selected-pressed-state-layer-color: #444444 !important;
  --mdc-switch-selected-focus-handle-color: #333333 !important;
  --mdc-switch-selected-hover-handle-color: #aaaaaa !important;
  --mdc-switch-selected-pressed-handle-color: #333333 !important;
  --mdc-switch-selected-focus-track-color: #aaaaaa !important;
  --mdc-switch-selected-hover-track-color: #666666 !important;
  --mdc-switch-selected-pressed-track-color: #888888 !important;
  --mdc-switch-selected-track-color: #7f7f7f !important;
}

.dialog-header {
  background-color: #3f3f3f;
  color: white;
  h2 {
    font-size: var(--mdc-dialog-subhead-size, 14px);
  }
  .close-button {
    cursor: pointer;
  }
}
.mat-mdc-dialog-surface {
  backdrop-filter: blur(6px);
  --mdc-dialog-container-color: rgba(255,255,255, 0.80) !important;

  app-text-area {
    textarea {
      background-color: #ffffff00 !important;
    }
  }
}

input.masked-input {
  font-family: monospace;
  letter-spacing: 2px;
  -webkit-text-security: disc;
  text-security: disc;
}
.masked-input input {
  font-family: monospace;
  letter-spacing: 2px;
  -webkit-text-security: disc;
  text-security: disc;
}
.ng-invalid .masked-input input.ng-dirty {
  color: red !important;
}

.dialog-header {
  padding: 4px 10px;
}

.block-ui-wrapper {
  opacity: 0 !important;
}
.hidden {
  display: none !important;
}

.no-opacity {
  opacity: 0 !important;
  transition: none !important;
}


.break-line {
  display: inline-block;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}


.pre-line {
  white-space: pre-line;
}

.markdown {
  ul, ol {
    line-height: 8px;
    padding-left: 1.5rem;
    margin: 0;
  }
  p {
    margin: 0;
    all: unset;
  }
  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
  }
}
